// src/components/AppContent.tsx
import parse from "html-react-parser";
import React, { useState, useCallback } from "react";
import { DateRangePicker } from "./DateRangePicker";
import { GoogleAuthButton } from "./GoogleAuthButton";
import { useGoogleAuth } from "../hooks/useGoogleAuth";
import {
  ChevronDown,
  ChevronUp,
  Clock,
  FileSpreadsheet,
  ShieldCheck,
  LucideIcon,
} from "lucide-react";
import { Banner } from "./Banner";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (!BACKEND_URL) {
  console.error("REACT_APP_BACKEND_URL is not defined in the environment");
}

interface FaqItem {
  isOpen: boolean;
  question: string;
  answer: string;
}

interface Benefit {
  icon: React.ReactElement<LucideIcon>;
  text: string;
}

export const AppContent: React.FC = () => {
  const { isAuthorized, login, error: authError } = useGoogleAuth();
  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), // 30 days ago
    endDate: new Date(),
  });

  const handleDateRangeChange = useCallback(
    (startDate: Date, endDate: Date) => {
      setDateRange({ startDate, endDate });
    },
    []
  );

  const handleExport = async () => {
    if (!BACKEND_URL) {
      setExportError("Backend URL is not defined");
      return;
    }

    setIsExporting(true);
    setExportError(null);

    try {
      const response = await fetch(`${BACKEND_URL}/api/export-events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to export events");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "calendar_events.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Export error:", error);
      setExportError("Failed to export events");
    } finally {
      setIsExporting(false);
    }
  };

  const defaultFaqItems: FaqItem[] = [
    {
      isOpen: true,
      question: "How does the Google Calendar to Excel export work?",
      answer:
        "Our tool securely connects to your Google Calendar, retrieves your events, and formats them into an Excel or CSV file. You can customize the date range and event details to export.",
    },
    {
      isOpen: true,
      question: "Is my calendar data safe?",
      answer:
        "Absolutely. We prioritize your privacy and security. We only access your calendar data during the export process and do not store any of your information on our servers.",
    },
    {
      isOpen: true,
      question: "Can I automate the export process?",
      answer:
        "Yes! You can set up recurring exports of calendar events in TimeCamp product. This feature is perfect for regular reporting and analysis.",
    },
    {
      isOpen: true,
      question: "How do I export Google Calendar to Excel?",
      answer:
        "With TimeCamp’s Google Calendar Export Tool, you can easily convert your calendar events to Excel. Simply sign in with your Google account, select your calendar, and download the file in CSV format.",
    },
    {
      isOpen: true,
      question: "Can I export Google Calendar to CSV?",
      answer:
        "Yes! TimeCamp provides a one-click solution to export Google Calendar events to CSV. Just connect your Google account, choose the calendar you want to export, and download the CSV file instantly.",
    },
    {
      isOpen: true,
      question: "How do I back up my Google Calendar?",
      answer:
        "To create a secure backup, use TimeCamp’s export feature. This allows you to save your calendar data as an CSV file (that can be opened by Excel), ensuring you always have access to past events.",
    },
    {
      isOpen: true,
      question: "Is there an automated way to export Google Calendar to Excel?",
      answer:
        "Yes! TimeCamp simplifies the process by allowing you to export your Google Calendar data in just a few clicks. You can download your events in Excel or CSV format whenever needed by creating a TimeCamp account and linking your calendar.",
    },
    {
      isOpen: true,
      question: "Can I extract events from Google Calendar into Excel?",
      answer:
        "Absolutely! TimeCamp extracts all event details—titles, dates, times, and descriptions—into an CSV file, making it easy to analyze or share your schedule.",
    },
    {
      isOpen: true,
      question: "Can I download my Google Calendar for offline use in Excel?",
      answer:
        "Yes, with TimeCamp’s tool, you can download your Google Calendar data as an Excel file, giving you access to your schedule even without an internet connection.",
    },
    {
      isOpen: true,
      question: "How do I import an exported calendar into Excel?",
      answer:
        "TimeCamp provides the data in a structured Excel format. Simply open the downloaded file in Excel, and you’ll see all your events neatly organized and ready for use.",
    },
    {
      isOpen: true,
      question: "What details are included when exporting Google Calendar?",
      answer: `
        <p>TimeCamp exports key event details, including:</p>
        <ul class="list-disc pl-6">
          <li>Event Title</li>
          <li>Start and End Time</li>
          <li>Event Description</li>
          <li>Location</li>
          <li>Attendees (if applicable)</li>
        </ul>
    `,
    },
    {
      isOpen: true,
      question: "Can I export multiple Google Calendars at once?",
      answer:
        "Yes! TimeCamp allows you to select and export multiple calendars, making it easy to consolidate all your events into a single Excel or CSV file.",
    },
    {
      isOpen: true,
      question: "Does TimeCamp support recurring events?",
      answer:
        "Absolutely! TimeCamp exports both one-time and recurring events, ensuring your full schedule is accurately transferred to Excel.",
    },
    {
      isOpen: true,
      question: "How often can I export my Google Calendar?",
      answer:
        "There are no limits! You can use TimeCamp’s export tool as often as you like to keep your Excel or CSV files up to date.",
    },
    {
      isOpen: true,
      question: "Is there a way to export Google Calendar with color labels?",
      answer:
        "Currently, Google does not allow exporting event colors. However, you can manually add labels in Excel after exporting with TimeCamp.",
    },
    {
      isOpen: true,
      question: "Can I export Google Calendar with meeting attendees?",
      answer:
        "Yes, TimeCamp includes attendee details in your exported file, making it easy to track who attended each meeting.",
    },
    {
      isOpen: true,
      question: "Is my data safe when using TimeCamp’s export tool?",
      answer:
        "Yes! TimeCamp uses secure authentication and does not store your calendar data after export.",
    },
    {
      isOpen: true,
      question: "Can I use TimeCamp’s export tool on mobile?",
      answer:
        "Yes, TimeCamp’s tool is web-based and works on any device, including smartphones and tablets.",
    },
  ];

  const [newFaq, setNewFaq] = useState<FaqItem[]>(defaultFaqItems);

  const toggleFaq = (index: number): void => {
    setNewFaq((prevFaq) =>
      prevFaq.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  const benefits: Benefit[] = [
    {
      icon: <Clock className="w-6 h-6" />,
      text: "Save time on manual data entry",
    },
    {
      icon: <FileSpreadsheet className="w-6 h-6" />,
      text: "Analyze your time usage in Excel",
    },
    {
      icon: <ShieldCheck className="w-6 h-6" />,
      text: "Secure and privacy-focused",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
      <div className="min-h-screen bg-gray-100 py-10 flex flex-col justify-center sm:py-12">
        <div className="relative py-8 ">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-8 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <h1 className="text-4xl font-bold mb-4">
              Export Google Calendar to Excel/CSV
            </h1>
            <p className="text-lg mb-6 max-w-2xl mx-auto">
              Unlock the power of your calendar data! Effortlessly export your
              Google Calendar events to Excel or CSV.
              <span className="bg-yellow-300 text-black rounded px-1 m-1">
                No signup required.
              </span>
              Just login with your Google account.
            </p>

            <GoogleAuthButton onLogin={login} isAuthorized={isAuthorized} />

            {authError && <p className="text-red-500 mt-2">{authError}</p>}
            <div className="my-8">
              <DateRangePicker
                onDateRangeChange={handleDateRangeChange}
                initialStartDate={dateRange.startDate}
                initialEndDate={dateRange.endDate}
              />
            </div>
            {exportError && <p className="text-red-500 mt-2">{exportError}</p>}
            <button
              onClick={handleExport}
              className={`bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-lg mt-4 ${
                isExporting || !isAuthorized
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={isExporting || !isAuthorized}
            >
              {isExporting ? "Processing..." : "Export Events"}
            </button>

            <br />
            <br />
            <br />

            <Banner />

            <br />
            <br />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex flex-col items-center">
                  {benefit.icon}
                  <p className="mt-2">{benefit.text}</p>
                </div>
              ))}
            </div>

            <br />
            <br />

            <h2 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h2>
            <div className="w-full max-w-2xl">
              {newFaq.map((item, idx) => (
                <div className="w-full max-w-2xl" key={idx}>
                  <div className="mb-4 border-b border-gray-200 pb-2">
                    <button
                      className="flex justify-between items-center w-full text-left font-semibold"
                      onClick={() => toggleFaq(idx)}
                    >
                      {item.question}
                      {item.isOpen ? <ChevronUp /> : <ChevronDown />}
                    </button>
                    {item.isOpen && (
                      <p className="mt-2 text-left">{parse(item.answer)}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-8 text-sm flex items-center justify-center gap-4">
              <a
                href="https://www.timecamp.com/privacy-policy/"
                className="text-blue-600 hover:underline"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.timecamp.com"
                className="text-blue-600 hover:underline"
              >
                Powered by TimeCamp
              </a>
              <a
                href="mailto:k.rudnicki+calendar@timecamp.com"
                className="text-blue-600 hover:underline"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
