// src/components/GoogleAuthButton.tsx
import React from "react";

interface GoogleAuthButtonProps {
  onLogin: () => void;
  isAuthorized: boolean;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  onLogin,
  isAuthorized,
}) => {
  return (
    <button
      onClick={onLogin}
      className={`text-white font-bold py-3 px-6 rounded-lg mb-4 hover:bg-[#259c4f] transition duration-300 ${
        isAuthorized ? "bg-[#2df172]" : "bg-[#25CF60]"
      }`}
      disabled={isAuthorized}
    >
      {isAuthorized ? "Authorized" : "Authorize Google Calendar for free"}
    </button>
  );
};
