import TimecampLogoGreen from "../timecamp-logo-green.svg";
import TimecampLogoWhite from "../timecamp-logo-white.svg";

export const Banner = () => {
  return (
    <div className="w-full p-8 bg-[#F2FCF6] flex flex-col items-center gap-6">
      <p className="text-lg">
        Export Google Calendar as timesheet using TimeCamp
      </p>
      <a
        href="https://app.timecamp.com/auth/register"
        className="border border-[#25CF60] py-[14px] min-w-[250px] rounded-md font-bold flex items-center justify-center gap-2 hover:bg-[#25CF60] hover:text-white transition-all group"
      >
        Track time free
        <div className="relative w-6 h-6">
          <img
            src={TimecampLogoGreen}
            alt=""
            className="absolute transition-opacity group-hover:opacity-0"
          />
          <img
            src={TimecampLogoWhite}
            alt=""
            className="absolute opacity-0 transition-opacity group-hover:opacity-100"
          />
        </div>
      </a>
    </div>
  );
};
